import {Form} from "./component/form/Form";

function App() {
  return (
    <div style={{background: "gray", width: "100%", minHeight: "100vh", display : "flex", justifyContent: "center", alignItems: "center"}}>
      <Form/>
    </div>
  );
}

export default App;
