import "./Form.css"
import {useState} from "react";
import axios from "axios";

export function Form() {

    const [restaurantName, setRestaurantName] = useState("");
    const [managerName, setManagerName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [tableNumbers, setTableNumbers] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [currency, setCurrency] = useState("");
    const [restaurantCategory, setRestaurantCategory] = useState("");
    const [openHours, setOpenHours] = useState("");
    const [closeHours, setCloseHours] = useState("");
    const [address, setAddress] = useState("");
    const [postalCode, setPostalCode] = useState("")
    const emailValidator = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    function clearData() {
        setRestaurantName("");
        setManagerName("");
        setEmail("");
        setPhoneNumber("");
        setTableNumbers("");
        setPostalCode("");
        setAddress("");
        setCity("");
        setCountry("");
        setCurrency("");
        setRestaurantCategory("");
        setOpenHours("");
        setCloseHours("");
    }

    function onSubmit() {
        if (restaurantName.length < 2) {
            alert("restaurant Name must not be empty")
        } else if (managerName.length < 2) {
            alert("Manager Name must not be empty")
        } else if (!email.match(emailValidator)) {
            alert("email is not correct")
        } else if (phoneNumber.length < 10) {
            alert("phone number is not correct")
        } else if (tableNumbers.length < 1) {
            alert("you must enter tables number")
        } else if (city.length < 2) {
            alert("please enter a valid city name")
        } else if (country.length < 2) {
            alert("please enter a valid country name")
        } else if (currency.length < 1) {
            alert("please enter a valid currency name")
        } else if (restaurantCategory < 2) {
            alert("please enter a valid restaurant Category")
        } else if (openHours < 5) {
            alert("please enter a valid open hours")
        } else if (postalCode.length < 1) {
            alert("please enter a valid Postal code")
        } else if (address.length < 2) {
            alert("please enter a valid Address")
        }
        else if (managerName.length < 2) {
                alert("Manager Name must not be empty")
        } else {
            const json = {
                restaurantName,
                managerName,
                email,
                phoneNumber,
                tableNumbers,
                city,
                country,
                currency,
                restaurantCategory,
                openHours,
                closeHours,
                postalCode,
                address
            }
            axios.post('https://dev-api.gling-gling.com/v1/miscs/sign-in-requests', json)
                .then(() => {
                    clearData();
                }).catch(err => {
                console.error(err)
            });

        }
    }


    return (
        <form>
            <div className={"logo-img"} style={{marginBottom: "50px"}}></div>
            <div style={{display: "flex", flexDirection: "column"}}>
                <input className={"input-sheet"}
                       type={"text"}
                       placeholder={"Restaurant Name"}
                       onChange={(event) => {
                           setRestaurantName(event.target.value)
                       }}
                       value={restaurantName}
                />
                <input className={"input-sheet mt"}
                       type={"text"}
                       placeholder={"Manager name"}
                       onChange={(event) => {
                           setManagerName(event.target.value)
                       }}
                       value={managerName}
                />
                <input className={"input-sheet mt"}
                       type={"email"}
                       placeholder={"Email"}
                       onChange={(event) => {
                           setEmail(event.target.value)
                       }}
                       value={email}
                />
                <div className={"input-holder rows"}>
                <input className={"input-sheet "}
                       type={"text"}
                       placeholder={"Phone number"}
                       onChange={(event) => {
                           setPhoneNumber(event.target.value)
                       }}
                       value={phoneNumber}
                />
                    <input className={"input-sheet ml rows"}
                           type={"text"}
                           placeholder={"Postal code"}
                           onChange={(event) => {
                               setPostalCode(event.target.value)
                           }}
                           value={postalCode}
                    />
                </div>
                <div className={"input-holder rows"}>
                <input className={"input-sheet"}
                       type={"text"}
                       placeholder={"table numbers"}
                       onChange={(event) => {
                           setTableNumbers(event.target.value)
                       }}
                       value={tableNumbers}
                />
                    <input className={"input-sheet ml rows"}
                           type={"text"}
                           placeholder={"Full Address"}
                           onChange={(event) => {
                               setAddress(event.target.value)
                           }}
                           value={address}
                    />
                </div>
            </div>
            <div className={"input-holder rows"}>
                <input className={"input-sheet"}
                       type={"text"}
                       placeholder={"City"}
                       onChange={(event) => {
                           setCity(event.target.value)
                       }}
                       value={city}
                />
                <input className={"input-sheet ml rows"}
                       type={"text"}
                       placeholder={"Country"}
                       onChange={(event) => {
                           setCountry(event.target.value)
                       }}
                       value={country}
                />
            </div>
            <div className={"input-holder rows"}>
            <input className={"input-sheet"}
                   type={"text"}
                   placeholder={"currency"}
                   onChange={(event) => {
                       setCurrency(event.target.value)
                   }}
                   value={currency}
            />
            <input className={"input-sheet  ml rows"}
                   type={"text"}
                   placeholder={"restaurant category"}
                   onChange={(event) => {
                       setRestaurantCategory(event.target.value)
                   }}
                   value={restaurantCategory}
            />
            </div>
            <div className={"input-holder rows"}>
                <input className={"input-sheet "}
                       type={"text"}
                       placeholder={"open hours"}
                       onChange={(event) => {
                           setOpenHours(event.target.value)
                       }}
                       value={openHours}
                />
                <input className={"input-sheet ml rows"}
                       type={"text"}
                       placeholder={"close hours"}
                       onChange={(event) => {
                           setCloseHours(event.target.value)
                       }}
                       value={closeHours}
                />
            </div>
            <div className={"btn"} onClick={onSubmit}>
                Submit
            </div>
        </form>
    )
}